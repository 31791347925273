<template>
  <div class="skin-inquiry-new-modal">
    <sweet-modal ref="modal" width="848px" overlay-theme="dark" @close="close">
      <template v-if="!vDone">
        <div class="h6 main text-left">신규 수정 요청</div>
        <skin-inquiry :value.sync="inquiry"></skin-inquiry>
        <div class="flex-center">
          <button class="button button-inquiry is-primary" @click="clickInquiry">신규 수정 요청</button>
        </div>
      </template>
      <div v-else style="padding-top: 40px">
        <div class="subtitle4 text-center">수정문의가 등록되었습니다.<br>빠른시일 안에 답변해드리겠습니다.</div>
        <div class="flex-center">
          <button class="button button-done is-gray" @click="$refs.modal.close()">확인</button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
  import { SweetModal } from "sweet-modal-vue";
  import SkinInquiry from "../component/SkinInquiry";
  import UserAPIMixin from "../../mixins/UserAPIMixin";

  export default {
    name: "SkinInquiryNewModal",
    mixins: [
      UserAPIMixin
    ],
    components: {
      SkinInquiry,
      SweetModal
    },
    created() {

    },
    data() {
      return {
        inquiry: {
          category_id: 0,
          content: '',
          imgs: []
        },
        vDone: false
      }
    },
    methods: {
      open() {
        this.vDone = false;
        setTimeout(()=>{
          this.$refs.modal.open();
        }, 200);
      },
      close() {
        this.$emit('done');
      },
      clickInquiry() {
        if(this.inquiry.category_id === 0) {
          this.toast('문의 유형을 선택해주세요.');
          return;
        }
        this.request.user.post(`launchpack/v1/service/${this.$store.getters.admin.service_id}/skin_inquiry`, this.inquiry).then(res => {
          if(res.status === 200) {
            this.vDone = true;
          }
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .button-inquiry
    width 220px
    height 44px
    margin-top 40px

  .button-done
    width 300px
    height 44px
    margin-top 40px
</style>
<style lang="stylus">
  .skin-inquiry-new-modal
    .sweet-modal.is-alert .sweet-content
      padding 32px
      text-align left
</style>
