<template>
  <div class="skin-inquiry-modal">
    <sweet-modal ref="modal" width="1216px" overlay-theme="dark">
      <div class="h6 main text-left">추가수정 내역</div>
      <button class="button is-primary flex-center" style="gap:8px;margin:20px 0 32px 0;width:194px;height:40px" @click="clickNew">
        <svg-icon icon="u_edit-alt" color="white"></svg-icon>
        <div>신규 수정 요청</div>
      </button>
      <div class="grid-th">
        <div class="th-item">상태</div>
        <div class="th-item">문의 유형</div>
        <div class="th-item">신청내용</div>
        <div class="th-item">신청날짜</div>
      </div>
      <div v-for="(item,idx) in list" :key="`idx-${idx}`"
        class="grid-td" @click="clickItem(item)">
        <div class="td-item"><div :class="`tag tag${item.status}`">{{item.status_kor }}
          <div v-if="item.not_read>0" class="circle-new">{{ item.not_read }}</div>
        </div></div>
        <div class="td-item body4 sub">{{ item.category }}</div>
        <div class="td-item body4 sub">{{ item.content.substring(0, 40) }}</div>
        <div class="td-item body4 sub3">{{ datesFormat(item.created_time, 'date_3') }}</div>
      </div>
    </sweet-modal>
    <skin-inquiry-new-modal ref="newModal" @done="getData"></skin-inquiry-new-modal>
  </div>
</template>

<script>
  import { SweetModal } from "sweet-modal-vue";
  import SvgIcon from "../component/SvgIcon";
  import SkinInquiryNewModal from "./SkinInquiryNewModal";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  export default {
    name: "SkinInquiryModal",
    mixins: [
      UserAPIMixin
    ],
    components: {
      SkinInquiryNewModal,
      SweetModal,
      SvgIcon
    },
    created() {
    },
    data() {
      return {
        list: []
      }
    },
    methods: {
      getData() {
        this.request.user.get(`launchpack/v1/service/${this.$store.getters.admin.service_id}/skin_inquiry`).then(res => {
          this.list = res.data.data;
        });
      },
      clickItem(item) {
        this.$refs.modal.close();
        setTimeout(()=>{
          this.routerPush(`/skin_inquiry_detail?id=${item.id}`);
        },300);
      },
      open() {
        this.getData();
        this.$refs.modal.open();
      },
      clickNew() {
        this.$refs.newModal.open();
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .grid-th
  .grid-td
    display grid
    grid-template-columns 120px 120px 1fr 120px
    grid-column-gap 40px
    padding 18px 0
    border-bottom 1px $gray2

  .grid-th
    padding-top 0
    border-bottom 1px solid $sub5
  .grid-td
    border-bottom 1px solid $gray2

  .th-item
    font-size 14px
    font-weight 700
    line-height 22px
    text-align left
  .td-item
    text-align left
    position relative

  .th-item:last-child
  .td-item:last-child
    text-align center

  .grid-td:hover
    background-color $gray4
    cursor pointer

  .circle-new
    width 16px
    height 16px
    line-height 16px
    background-color $error
    border-radius 100%
    text-align center
    color white
    font-size 12px
    font-weight 500
    position absolute
    top -8px
    right -8px

  .tag
    text-align center
    border-radius 4px
    padding 2px 0 4px 0
    width 100%
    font-size 13px
    font-weight 500
    line-height 20px
  .tag0
    background-color $gray2
    color $sub2
  .tag1
    background-color $error50
    color $error
  .tag2
  .tag3
  .tag4
    background-color $subcolor50
    color $subcolor2

</style>
<style lang="stylus">
  .skin-inquiry-modal
    .sweet-modal.is-alert .sweet-content
      padding 32px
  .sweet-modal-overlay
    -webkit-perspective initial
</style>
